<template>
  <div class="home" :class="{ final: index == questions.length }">
    <transition name="fade" mode="out-in">
      <Question
        v-if="index < questions.length"
        :question="questions[index].q"
        :options="questions[index].o"
        :key="index"
        @next="score"
      />
      <Final
        v-if="index == questions.length"
        :title="winner.title"
        :text="winner.text"
        :restart="winner.restart"
        :key="index"
        :class="{rr: points[1] > points[0], ac: points[0] > points[1]}"
        @restart="reset"
      />
    </transition>
    <transition name="fade" mode="out-in">
      <div class="progress" v-if="index > 0 && index < questions.length">
        <div class="level" v-for="i in questions.length - 1" :key="i" :class="{muted: index < i}"></div>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import Question from '@/components/Question.vue'
import Final from '@/components/Final.vue'

export default {
  name: 'Home',
  components: {
    Question,
    Final,
  },
  methods: {
    score(side) {
      if( side != null ) {
        this.points[side]++
      }
      this.index++
    },
    reset() {
      this.index = 0
      this.points = [0,0]
    },
  },
  computed: {
    winner() {
      if(this.points[1] > this.points[0]) {
        return this.finals[1]
      }
      return this.finals[0]
    }
  },
  data() {
    return {
      index: 0,
      points: [0,0],
      questions: [
        {
          q: "Descobre o teu<br>primeiro-ministro",
          o: [{o:'Começar o quiz'}],
        },
        {
          q: "Onde preferias gastar 4 milhões de euros?",
          o: [{o:'TAP', s: 0}, {o:'SNS', s: 1}],
        },
        {
          q: "Contratavas mais médicos de família ou mais ministros da mesma família?",
          o: [{o:'Médicos', s: 1}, {o:'Ministros', s: 0}],
        },
        {
          q: "Investias no combate à corrupção ou mantinhas a taxa de prescrição de crimes?",
          o: [{o:'Combater', s: 1}, {o: 'Prescrever', s: 0}],
        },
        {
          q: "Prometer o impossível ou cumprir o possível?",
          o: [{o:'Prometer', s: 0}, {o: 'Cumprir', s: 1}],
        },
        {
          q: "Continuar a injetar dinheiro no NOVO Banco ou melhorar carreiras dos médicos e professores?",
          o: [{o:'Novo Banco', s: 0}, {o: 'Carreiras', s: 1}],
        },
        {
          q: "Concordas com uma justiça fraca com os fortes e forte com os fracos?",
          o: [{o:'Sim, Faz Sentido', s: 0}, {o: 'Claro que não', s: 1}],
        },
        {
          q: "Mais taxas para os pequenos empresários ou para as grandes empresas?",
          o: [{o:'Pequenos', s: 0}, {o: 'Grandes', s: 1}],
        },
      ],
      finals: [
        {
          title: 'Continuar com António Costa',
          text: `
            O País vai continuar como tem estado, na cauda da Europa.<br>
            Sem conseguir executar os fundos europeus que recebe e a ver os seus melhores talentos sair à procura de melhores salários.<br>
            Podias ter escolhido um Governo dinâmico e inovador, mas escolheste um que vai continuar a prometer em vez de agir.<br>
            <br>
            Tens a certeza?
          `,
          restart: 'Quero voltar a tentar'
        },
        {
          title: 'Mudar<br>com Rui Rio',
          text: `
            Tens esperança no nosso país e queres que este volte a crescer.<br>
            Queres um Governo reformista que não se conforma com um país na cauda da Europa.<br>
            Queres um SNS mais forte, uma educação eficiente e transportes públicos que funcionem.<br>
            Queres reduzir a dívida e garantir o futuro das gerações futuras.<br>
            Queres libertar os pequenos e médios empresários para que estes também possam produzir riqueza para o nosso país.<br>
            Com o PSD vais ter um Governo que te defende, venhas de onde vieres e votes em quem votares. <br>
            Juntos vamos construir novos horizontes.
          `,
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/queries";
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.home {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: grid;
  gap: 15vh;
  grid-template-areas:
    "question"
    "progress"
  ;
  grid-template-rows: 1fr 15vh;
  @include media(tablet) {
    grid-template-rows: 1fr 10vh;
  }
  // &.final {
  //   grid-template-areas: "question";
  //   grid-template-rows: 1fr;
  // }
}
.progress {
  width: 100%;
  height: 4px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  max-width: 800px;
  margin: 0 auto;
  .level {
    border-radius: 4px;
    flex-grow: 1;
    height: 100%;
    background-color: white;
    transition: .2s ease-out opacity;
    &.muted {
      opacity: .3;
    }
  }
}
</style>
