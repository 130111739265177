<template>
  <div class="final">
    <h2>Escolheste</h2>
    <h1 v-html="title"></h1>
    <p class="text" v-html="text"></p>
    <div class="bg"></div>
    <a href="#" @click.prevent="goBack" v-text="restart" v-if="restart">Restart</a>
    <ShareNetwork
      v-else
      network="facebook"
      :url="url"
      title="Mudar com Rui Rio"
      description="Mudar com Rui Rio"
      quote="Mudar com Rui Rio"
      hashtags="psd"
      >
      Partilha no Facebook
    </ShareNetwork>
  </div>
</template>

<script>

export default {
  name: 'Question',
  components: {
  },
  props: {
    title: String,
    text: String,
    restart: String,
  },
  computed: {
    url() {
      return window.location.href
    }
  },
  methods: {
    goBack() {
      this.$emit('restart')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/queries";

.final {
  width: 100%;
  margin: auto 0;
  @include media(tablet) {
    width: 50%;
  }
  &.rr {
    margin: auto 0 auto auto;
    &::after {
      background-image: url('../assets/rr.png');
    }
  }
  &.ac::after {
    background-image: url('../assets/ac.png');
    background-position: right;
  }
  &::after {
    z-index: 0;
    position: fixed;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: auto 100%;
    pointer-events: none;
    @include media(mobile) {
      background-position: center !important;
      opacity: .3;
      // mix-blend-mode:screendd;
    }
  }
  * {
    z-index: 10;
    position: relative;
    filter: drop-shadow(0px 1px solid black);
  }
}
h1, h2 {
  color: white;
  text-transform: uppercase;
  line-height: 1;
  text-align: left;
  font-size: 4rem;
  margin: 0;
  @include media(desktop) {
    font-size: 2.5rem;
  }
}
h2 {
  color: #ca2500;
  margin-bottom: .25rem;
}
p {
  color: white;
  text-align: left;
  font-family: "Akkurat", Helvetica, Arial, sans-serif;
  font-size: 1.85rem;
  margin-bottom: 4rem;
  @include media(mobile) {
    line-height: 1.1;
    margin: 1rem 0;
  }
  @include media(tablet) {
    font-size: 1.5rem;
  }
  @include media(desktop) {
    font-size: 1rem;
  }
}
a {
  background-color: #ff3000;
  padding: .25em 1em;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 3rem;
  display: block;
  text-decoration: none;
  width: fit-content;
  margin: 0 auto;
  font-size: 2rem;
  transition: .1s ease-out transform;
  @include media(tablet) {
    font-size: 1.25rem;
  }
  color: white;
  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(.95);
    }
  }
}
</style>
