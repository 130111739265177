<template>
<div class="question">
  <h1 v-html="question"></h1>
  <ul>
    <li v-for="option, index in options"
      :key="index"
      v-text="option.o"
      @click.prevent="next(option.s)">
    </li>
  </ul>
</div>
</template>

<script>
export default {
  name: 'Question',
  props: {
    question: String,
    options: {default: []},
  },
  methods: {
    next(side) {
      this.$emit('next', side)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/queries";

.question {
  margin: auto;
  width: 100%;
  max-width: 800px;
  display: grid;
  grid-template-rows: 1fr min-content;
  height: 100%;
  // padding: 15vh 0;
  grid-template-areas:
    "question"
    "options"
  ;
}
h1 {
  color: white;
  text-transform: uppercase;
  grid-area: question;
  margin: auto;
  line-height: 1.15;
  font-size: 3.5rem;
  @include media(tablte) {
    font-size: 4.3rem;
  }
}
ul {
  grid-area: options;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  @include media(mobile) {
    flex-direction: column;
    gap: 2rem;
  }
  li {
    background-color: #ff3000;
    padding: .25em;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 3rem;
    cursor: pointer;
    transition: .1s ease-out transform;
    font-size: 2rem;
    min-width: 200px;
    @include media(tablet) {
      font-size: 1.25rem;
      min-width: 250px;
    }
    color: white;
    @media (hover: hover) {
      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(.95);
      }
    }
  }
}
</style>
